import React from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./ConfirmPopUp.css";

const ConfirmPopUp = ({
  children,
  confirmFN,
  message,
  heading,
  type,
  className,
  loading = false,
}) => {
  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body confirm-popup-button-body">
            <h2 className="fw-700">{heading}</h2>
            <div>{message}</div>
            <div className="btn-group d-flex justify-content-end">
              <button
                className={
                  "confirm-popup-button" +
                  (type === "delete"
                    ? " confirm-button-delete"
                    : " confirm-button-update")
                }
                onClick={() => {
                  confirmFN();
                  onClose();
                }}
              >
                {type === "delete" ? "Delete" : "Yes"}
              </button>
              <button
                className={
                  "cancel-popup-button" +
                  (type === "delete"
                    ? " cancel-button-delete"
                    : " cancel-button-update")
                }
                onClick={onClose}
              >
                No
              </button>
            </div>
          </div>
        );
      },
      closeOnClickOutside: true,
    });
  };

  return (
    <span className={className} onClick={submit}>
      {children}
    </span>
  );
};
export default ConfirmPopUp;
