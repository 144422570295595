import React, { useContext, useEffect, useState } from "react";
import { IoLocation } from "react-icons/io5";
import { getUser, updateUserData } from "../../../../api";
import AddressField from "../../../../components/AddressField";
import { AppContext } from "../../../../context/AppContext";
import Loader from "react-loader-spinner";
import "./AddressBook.css";
import { confirmAlert } from "react-confirm-alert";

const AddressBook = () => {
  const { profile, store, setShowToast, setToastData } = useContext(AppContext);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [checkValidation, setCheckValidation] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [edit, setEdit] = useState(false);
  const [heading, setHeading] = useState();
  const [dataLoading, setDataLoading] = useState(false);
  const [address, setAddress] = useState({
    firstname: "",
    lastname: "",
    telephone: "",
    email: "",
    country_id: "",
    postcode: "",
    city: "",
    street: []
  });

  useEffect(() => {
    setDataLoading(true);
    getUser()
      .then(userres => {
        setAddresses(userres?.addresses);
      })
      .finally(() => setDataLoading(false));
  }, []);
  const removeItem = id => {
    return addresses?.filter(add => add.id !== id);
  };

  const handleDelete = id => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body confirm-popup-button-body">
            <h2 className="fw-700">{"Delete?"}</h2>
            <div>{"Are you sure about deleting this Address?"}</div>
            <div className="btn-group d-flex justify-content-end">
              <button
                className={"confirm-popup-button confirm-button-delete"}
                onClick={() => {
                  setDataLoading(true);
                  updateUserData({
                    customer: {
                      email: profile?.email,
                      firstname: profile?.firstname,
                      lastname: profile?.lastname,
                      website_id: store?.website_id,
                      addresses: removeItem(id),
                      custom_attributes: [
                        {
                          attribute_code: "mobile_number",
                          value: profile?.custom_attributes?.filter(
                            item => item?.attribute_code === "mobile_number"
                          )[0]?.value
                        }
                      ]
                    }
                  })
                    .then(res => {
                      setAddresses(res?.addresses);
                      setShowToast(true);
                      setToastData({
                        type: "success",
                        text: "Address Deleted"
                      });
                    })
                    .catch(e => console.log(e))
                    .finally(() => {
                      setDataLoading(false);
                      window.scroll({
                        top: 0,
                        left: 0,
                        behavior: "smooth"
                      });
                    });
                  onClose();
                }}
              >
                {"Delete"}
              </button>
              <button
                className={"cancel-popup-button cancel-button-delete"}
                onClick={onClose}
              >
                No
              </button>
            </div>
          </div>
        );
      },
      closeOnClickOutside: true
    });
  };

  const handleEdit = address => {
    setAddress(address);
    setHeading("EDIT ADDRESS");
    setShowAddAddress(true);
    setEdit(true);
  };

  const handleAddAddress = () => {
    setHeading("ADD NEW ADDRESS");
    setCheckValidation(false);
    setShowAddAddress(true);
    setEdit(false);
    setAddress({
      firstname: "",
      lastname: "",
      telephone: "",
      email: "",
      country_id: "",
      postcode: "",
      city: "",
      street: [""]
    });
  };

  return (
    <>
      {!showAddAddress ? (
        <>
          <div className="d-flex-column justify-content-between mb-3">
            <span className="profile-title my-2 mx-3">My Address</span>
            <p
              className="text-13 font-weight-normal green-color c-pointer border border-success rounded m-lg-3 p-lg-3 my-2 mx-1 py-3 px-0"
              onClick={handleAddAddress}
            >
              <span
                className="py-2 px-3 mx-2 rounded"
                style={{ background: "#00983d33" }}
              >
                +
              </span>
              ADD NEW DELIVERY ADDRESS
            </p>
          </div>
          <div className="position-relative">
            <Loader
              className="address-book-loader"
              type="ThreeDots"
              color={"mediumseagreen"}
              height={70}
              width={70}
              visible={dataLoading}
            />
            {addresses?.length > 0
              ? addresses?.map((address, index) => {
                  return (
                    <div
                      className="item-container row p-2 text-break mb-2 mx-lg-2 mx-1"
                      key={`${index}-${address?.id}`}
                    >
                      <div className="col-sm-1 d-flex justify-content-center align-items-center">
                        <IoLocation size={"25px"} color={"#00983d"} />
                      </div>
                      {/* ======================================== */}
                      <div className="col-lg-9 col-sm-11 col-12">
                        <div className="font-weight-bold">{`${address?.firstname} ${address?.lastname}`}</div>
                        <div>{`${address?.street}`}</div>
                        <div>
                          {`${address?.city}, ${
                            address?.country_id === "AE" ? "UAE" : ""
                          } ${
                            address?.postcode ? "- " + address?.postcode : ""
                          }`}
                        </div>
                      </div>
                      {/* ======================================== */}

                      <div className="col-lg-2 col-12 d-flex flex-lg-column flex-row justify-content-around">
                        <button
                          className="edit-btn-addr"
                          onClick={() => handleEdit(address)}
                        >
                          {"Edit"}
                        </button>
                        <button
                          className="delete-btn-addr"
                          onClick={() => handleDelete(address?.id)}
                        >
                          {"Delete"}
                        </button>
                      </div>
                    </div>
                  );
                })
              : !dataLoading && (
                  <div className="item-container my-3 mx-5 p-3 d-flex text-center justify-content-center">
                    No Address Found
                  </div>
                )}
          </div>
        </>
      ) : (
        <AddressField
          setAddress={setAddress}
          address={address}
          setShowAddAddress={setShowAddAddress}
          addresses={addresses}
          setAddresses={setAddresses}
          checkValidation={checkValidation}
          setCheckValidation={setCheckValidation}
          edit={edit}
          addressTo={"addressBook"}
          heading={heading}
          removeItem={removeItem}
        />
      )}
    </>
  );
};
export default AddressBook;
