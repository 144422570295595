import React, { useState, useEffect, useContext } from "react";
import "./DeliverySlots.css";
// REMOVE THIS IMPORT WHEN THE ICON IS AVAILABLE FROM API
// REMOVE THE "clock" IN JSX AND USE "props.iconURL"
import clock from "../../assets/images/clock.svg";
import bolt from "../../assets/images/bolt.svg";
import moment from "moment";
import { AppContext } from "../../context/AppContext";

function DeliverySlots(props) {
  const [deliveryInfo, setDeliveryInfo] = useState("Timeslots Open");
  const { timeSlots } = useContext(AppContext);

  const [hours, setHours] = useState();
  const [icon, setIcon] = useState(clock);

  useEffect(() => {
    if (props?.deliverNow) {
      setDeliveryInfo("Delivering Now In");
      if (props?.type === "express") {
        setIcon(bolt);
      } else {
        setIcon(clock);
      }

      setHours(
        moment(props?.fromTime).diff(moment().local(), "hours", true) ===
          parseInt(
            moment(props?.fromTime).diff(moment().local(), "hours", true)
          )
          ? `${parseInt(
              moment(props?.fromTime).diff(moment().local(), "hours", true)
            )}`
          : `${
              parseInt(
                moment(props?.fromTime).diff(moment().local(), "hours", true)
              ) + 1
            }`
      );
    } else if (props.index === 0) {
      setDeliveryInfo("Next Available Timeslot");
      if (props?.type === "express") {
        setIcon(bolt);
      } else {
        setIcon(clock);
      }
    } else if (props?.type === "express") {
      setDeliveryInfo("Express Timeslot");
      setIcon(bolt);
    } else {
      setDeliveryInfo("Timeslots Open");
      setIcon(clock);
    }
  }, [timeSlots]);
  return (
    <div
      id={props?.id}
      className={
        "delivery-slots d-inline-flex" +
        (hours ? " delivery-slots-first" : "") +
        (props.index === 0 ? " delivery-slots-second" : "")
      }
    >
      <img
        className={
          props.index === 0 ? "delivery-slots-icon" : "delivery-slots-icon-pink"
        }
        src={icon}
      ></img>
      <div className="delivery-slots-texts d-flex flex-column">
        <p className="delivery-slots-info">{deliveryInfo ?? ""}</p>
        {props?.deliverNow ? (
          <div style={{ display: "flex" }}>
            <p className="delivery-slots-time">
              {hours === 1 ? hours + " hour" : hours + " hours"}
            </p>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <p className="delivery-slots-time">
              {props?.day === "Tomorrow" && "Tom "}
              {moment(props.fromTime).format("hh:mm a")}
              &nbsp;-
            </p>
            <p className="delivery-slots-time">
              &nbsp;{moment(props.toTime).format("hh:mm a")}
            </p>
          </div>
        )}
      </div>
    </div>
  );
}

export default DeliverySlots;
