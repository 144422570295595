import React, { useState, useEffect } from "react";
import "./CartButton.css";
import { FaMinus, FaPlus } from "react-icons/fa";
import Loader from "react-loader-spinner";

const CartButton = props => {
  return (
    <div
      className="d-flex add-cart-button text-center justify-content-around align-items-center"
      style={{
        backgroundColor: props?.bg ? props?.bg : "#00983D",
        color: props?.color ? props?.color : "white",
        fontSize: props?.fontSize ? props?.fontSize : "12px",
        border: props?.border ? props?.border : "1px solid transparent"
      }}
    >
      {!props.isCartLoading ? (
        props?.updatedValue === 0 ? (
          <div
            className="w-100 p-2"
            onClick={() => {
              props?.setCartValue(props?.cartValue + 1);
              props?.setHasClicked(props?.hasClicked + 1);
            }}
          >
            {`Add to Cart`}
          </div>
        ) : (
          <>
            <div
              className="p-2"
              onClick={() => {
                props?.cartValue <= 0
                  ? props?.setCartValue(0)
                  : props?.setCartValue(props?.cartValue - 1);
                props?.setHasClicked(props?.hasClicked + 1);
              }}
            >
              <FaMinus />
            </div>
            <div className="p-2">{props?.updatedValue}</div>
            <div
              className="p-2"
              onClick={() => {
                props?.setCartValue(props?.cartValue + 1);
                props?.setHasClicked(props?.hasClicked + 1);
              }}
            >
              <FaPlus />
            </div>
          </>
        )
      ) : (
        <div className="d-flex w-100 justify-content-center align-items-center p-2">
          <Loader
            type="ThreeDots"
            color={props?.loaderColor ?? "white"}
            height={17}
            width={28}
          />
        </div>
      )}
    </div>
  );
};

export default CartButton;
