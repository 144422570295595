import React, { useContext, useEffect, useRef, useState } from "react";
import { Dropdown, Overlay, Popover } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { AppContext } from "../../context/AppContext";
import validate from "../../utils/formValidator";
import { updateUserData } from "../../api";
import { MdMyLocation } from "react-icons/md";
import { FiChevronDown } from "react-icons/fi";
const AddressField = ({
  setAddress,
  address,
  setShowAddAddress,
  addresses,
  setAddresses,
  edit = false,
  addressTo,
  removeItem,
  checkValidation,
  setCheckValidation,
  setDeliveryCords,
  heading = "ADD NEW ADDRESS"
}) => {
  const {
    setMapbar,
    profile,
    setShowToast,
    setToastData,
    store,
    latLong,
    addressLocation,
    updateAddressLocation,
    currentStore
  } = useContext(AppContext);

  const countryRef = useRef();
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState(971);
  const [open, setOpen] = useState(false);
  const selectCountry = code => {
    setCountryCode(code);
    setOpen(false);
  };

  const [addressLoading, setAddressLoading] = useState(false);
  const [mapSelected, setMapSelected] = useState(false);
  useEffect(() => {
    if (edit) {
      updateAddressLocation({
        lat: address?.custom_attributes?.filter(
          attribute => attribute.attribute_code === "latitude"
        )[0]?.value,
        long: address?.custom_attributes?.filter(
          attribute => attribute.attribute_code === "longitude"
        )[0]?.value,
        name: address?.custom_attributes?.filter(
          attribute => attribute.attribute_code === "location"
        )[0]?.value
      });
      if (
        address?.telephone.substring(0, 4) === "+971" ||
        address?.telephone.substring(0, 3) === "971" ||
        parseInt(address?.telephone)?.toString()?.length === 9
      ) {
        setCountryCode(971);
        setPhone(address?.telephone?.slice(address?.telephone?.length - 9));
      } else {
        setCountryCode(91);
        setPhone(address?.telephone?.slice(address?.telephone?.length - 10));
      }
    }
  }, [address?.custom_attributes]);

  useEffect(() => {
    if (!edit) {
      updateAddressLocation({
        lat: 0,
        long: 0,
        location: "",
        store_id: "",
        city: "",
        country: ""
      });
      setAddress({
        ...address,
        city: latLong?.city?.name || "",
        country_id: latLong?.country?.code || ""
      });
    }
  }, []);

  const [countryList] = useState([
    { code: "AE", name: "UAE" }
    // { code: "IN", name: "India" },
  ]);

  const [regionList] = useState({
    AE: [
      { name: "Abu Dhabi" },
      { name: "Dubai" },
      { name: "Sharjah" },
      { name: "Umm al-Qaiwain" },
      { name: "Fujairah" },
      { name: "Ajman" },
      { name: "Ra's al-Khaimah" }
    ]
    // IN: [{ name: "Calicut" }],
  });
  const handleSaveAddress = () => {
    setCheckValidation(true);
    if (
      addressLocation?.name &&
      address?.name !== "" &&
      address?.lastname !== "" &&
      address?.telephone !== "" &&
      address?.country_id !== "" &&
      address?.city !== "" &&
      address?.street[0] !== "" &&
      validate(address?.telephone, "tel")
    ) {
      let tempAddress = {};
      if (edit) {
        tempAddress = removeItem(address?.id);
      } else {
        tempAddress = addresses;
      }
      if (
        addressTo === "checkout" &&
        addressLocation?.store_id !== currentStore?.store_id
      ) {
        setToastData({
          type: "error",
          text: "We are currently not deliverying to the selected Location from this Store"
        });
        setShowToast(true);
        return 0;
      }
      setAddressLoading(true);
      const PAYLOAD = {
        customer: {
          id: profile?.id,
          email: profile?.email,
          firstname: profile?.firstname,
          lastname: profile?.lastname,
          website_id: store?.website_id,
          addresses: [
            ...tempAddress,
            {
              id: address?.id || 0,
              customer_id: profile?.id,
              firstname: address?.firstname,
              lastname: address?.lastname,
              region: {
                region_code: "UAE",
                region: "UAE",
                region_id: 43
              },
              region_id: 43,
              country_id: address?.country_id,
              street: [...address?.street],
              telephone: address?.telephone,
              postcode: address?.postcode || "",
              city: address?.city,
              custom_attributes: [
                {
                  attribute_code: "latitude",
                  value: addressLocation?.lat
                },
                {
                  attribute_code: "longitude",
                  value: addressLocation?.long
                },
                {
                  attribute_code: "location",
                  value: addressLocation?.name
                }
              ],
              default_billing: false,
              default_shipping: false
            }
          ],
          custom_attributes: [
            {
              attribute_code: "mobile_number",
              value: profile?.custom_attributes?.filter(
                item => item?.attribute_code === "mobile_number"
              )[0]?.value
            }
          ]
        }
      };
      updateUserData(PAYLOAD)
        .then(res => {
          addressTo !== "addressBook" &&
            setDeliveryCords({
              lat: addressLocation?.lat,
              long: addressLocation?.long,
              name: addressLocation?.name
            });
          setAddress({
            ...address,
            email: profile?.email,
            custom_attributes: [
              {
                attribute_code: "latitude",
                value: addressLocation?.lat
              },
              {
                attribute_code: "longitude",
                value: addressLocation?.long
              },
              {
                attribute_code: "location",
                value: addressLocation?.name
              }
            ]
          });
          setAddresses(res?.addresses);
          setShowToast(true);
          setToastData({
            type: "success",
            text: edit ? "Address Edited" : "Address Added"
          });
        })
        .catch(e => console.log(e))
        .finally(() => {
          setShowAddAddress(false);
          setAddressLoading(false);
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
          });
        });
    } else {
      setShowToast(true);
      setToastData({
        type: "error",
        text: "Please fill all required Fields"
      });
    }
  };

  useEffect(() => {
    mapSelected &&
      setAddress({
        ...address,
        country_id: addressLocation?.country?.code,
        city: addressLocation?.city?.name
      });
  }, [addressLocation?.city]);

  return (
    <div className="offset-md-1 offset-0 mb-lg-5 mb-0 mx-lg-3 mx-0">
      <div className="border-line-all container-radius">
        <div className="border-line p-3 m-0 d-flex justify-content-between">
          <span className="text-18 fw-500" style={{ color: "#00983d" }}>
            {heading}
          </span>
        </div>

        <div className="row mb-3 p-3">
          <div className="mb-2 col-md-6">
            <label className="text-14 mild-faded-color d-block">
              First Name
              {checkValidation && address?.firstname === "" && (
                <span className="required">*required</span>
              )}
            </label>
            <input
              maxLength="20"
              defaultValue={address?.firstname}
              className="w-100 bg-gray border-0 p-2 container-radius"
              placeholder="eg: John"
              onChange={e =>
                setAddress({
                  ...address,
                  firstname: e.target.value
                })
              }
            />
          </div>
          <div className="mb-2 col-md-6">
            <label className="text-14 mild-faded-color d-block">
              Last Name
              {checkValidation && address?.lastname === "" && (
                <span className="required">*required</span>
              )}
            </label>
            <input
              maxLength="40"
              defaultValue={address?.lastname}
              className="w-100 bg-gray border-0 p-2 container-radius"
              placeholder="eg: Doe"
              onChange={e =>
                setAddress({
                  ...address,
                  lastname: e.target.value
                })
              }
            />
          </div>
          <div className="mb-2 col-md-6">
            <label className="text-14 mild-faded-color d-block">
              Phone Number
              {checkValidation && address?.telephone === "" && (
                <span className="required">*required</span>
              )}
              {!validate(address?.telephone, "tel") &&
                checkValidation &&
                address?.telephone !== "" && (
                  <span className="required">*invalid phone</span>
                )}
            </label>

            <div
              className="phone-input d-flex align-items-center pl-1"
              style={{ position: "relative", zIndex: 1 }}
            >
              <span
                onClick={() => setOpen(old => !old)}
                className="phone-country-dropdown col-lg-2 col-3 c-pointer"
                ref={countryRef}
              >
                {`+${countryCode}`}
                <FiChevronDown
                  size="18"
                  className={
                    "ml-2 arrow-rotate" + (open === true ? " spin180" : "")
                  }
                />
              </span>
              <Overlay
                target={countryRef.current}
                placement="bottom"
                show={open}
              >
                <Popover
                  id="popover-basic"
                  className="d-flex flex-column country-popover"
                >
                  <Popover.Content
                    className="fs-14 c-pointer highlight"
                    onClick={() => {
                      setPhone("");
                      selectCountry(971);
                    }}
                  >
                    +971
                  </Popover.Content>
                  <Popover.Content
                    className="fs-14 c-pointer highlight"
                    onClick={() => {
                      setPhone("");
                      selectCountry(91);
                    }}
                  >
                    +91
                  </Popover.Content>
                </Popover>
              </Overlay>
              <input
                defaultValue={phone}
                onChange={e => {
                  setAddress({
                    ...address,
                    telephone: `+${countryCode}${e.target.value}`
                  });
                }}
                className="bg-gray border-0 p-2 container-radius w-100"
                maxLength={countryCode !== 91 ? 9 : 10}
                placeholder={`eg: ${
                  countryCode !== 91 ? "481297000" : "4812970000"
                }`}
                type="tel"
              />
            </div>
          </div>
          <div className="mb-2 col-md-6">
            <label className="text-14 mild-faded-color d-block">Zip Code</label>
            <input
              type="text"
              maxLength="6"
              defaultValue={address?.postcode}
              className="w-100 bg-gray border-0 p-2 container-radius"
              placeholder="eg: 0000"
              onChange={e =>
                setAddress({
                  ...address,
                  postcode: e.target.value
                })
              }
            />
          </div>
          <div className="mb-2 col-md-4">
            <label className="text-14 mild-faded-color d-block">
              Location
              {checkValidation && !addressLocation?.name && (
                <span className="required">*required</span>
              )}
            </label>
            <div
              className="form-control d-flex c-pointer p-0 align-items-center"
              onClick={() => {
                setMapbar(true);
                setMapSelected(true);
              }}
            >
              <span className="w-80 text-truncate overflow-hidden mx-2">
                {addressLocation?.name
                  ? addressLocation?.name
                  : "Select a Location"}
              </span>
              <span className="w-20 border-left text-center">
                <MdMyLocation fill="green" size="1.2rem" />
              </span>
            </div>
          </div>
          <div className="mb-2 col-md-4">
            <label className="text-14 mild-faded-color d-block">
              Country
              {checkValidation && !address?.country_id && (
                <span className="required">*required</span>
              )}
            </label>
            <div className="checkout-dropdown-small">
              <Dropdown>
                <Dropdown.Toggle className="d-flex align-items-center">
                  <span>
                    {countryList.filter(
                      item => item.code === address?.country_id
                    )[0]?.name || "Select a Country"}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu id={"countrySelect"}>
                  {countryList?.map((countryItem, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Dropdown.Item
                          onSelect={e => {
                            setAddress({
                              ...address,
                              country_id: countryItem.code
                            });
                          }}
                        >
                          {countryItem.name}
                        </Dropdown.Item>
                      </React.Fragment>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="mb-2 col-md-4">
            <label className="text-14 mild-faded-color d-block">
              Emirate
              {checkValidation && !address?.city && (
                <span className="required">*required</span>
              )}
            </label>
            <div className="checkout-dropdown-small">
              <Dropdown>
                <Dropdown.Toggle
                  className="d-flex align-items-center"
                  disabled={!address?.country_id}
                >
                  <span>{address?.city || "Select a City"}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {regionList[address?.country_id]?.map((regionItem, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Dropdown.Item
                          onSelect={() => {
                            setAddress({
                              ...address,
                              city: regionItem.name
                            });
                          }}
                        >
                          {regionItem.name}
                        </Dropdown.Item>
                      </React.Fragment>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="mb-2 col-12">
            <label className="text-14 mild-faded-color d-block">
              Address
              {checkValidation && address?.street[0] === "" && (
                <span className="required">*required</span>
              )}
            </label>
            <textarea
              maxLength="100"
              className="w-100 bg-gray border-0 p-2 container-radius address-text"
              placeholder=""
              defaultValue={address?.street[0]}
              onChange={e =>
                setAddress({
                  ...address,
                  street: [e.target.value]
                })
              }
            ></textarea>
          </div>
          <div className="d-flex w-100 justify-content-end mr-3">
            <button
              className="primary-button click-anim text-14 font-weight-bold mr-2"
              onClick={handleSaveAddress}
            >
              {addressLoading ? (
                <Loader
                  type="TailSpin"
                  color={"white"}
                  height={20}
                  width={20}
                />
              ) : addressTo === "addressBook" ? (
                "SAVE"
              ) : (
                "Deliver This Order Here"
              )}
            </button>
            <button
              className="primary-button click-anim text-14 font-weight-bold bg-white"
              style={{ color: "#00983d" }}
              onClick={() => {
                setShowAddAddress(false);
                window.scroll({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
              }}
            >
              CANCEL
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressField;
